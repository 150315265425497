
#token-image{
    width: 18vw;
}



.caroussel-animation-container{
  margin-left: auto;
  margin-right: auto;
  margin-left: 5vw;
}


@media screen and (max-width: 1500px) {
    .caroussel-animation-container{
        margin-top: 10%;
    }
}


@media screen and (min-width: 1500px) {
    .caroussel-animation-container{
        width: 70%;
    }
}


@media screen and (max-width: 879px) {
    .caroussel-animation-container{
        margin: auto;
    }
}