.roadmap-container{
    font-family: 'SpaceGrotesk';
    
}



.header-roadmap{
    margin-bottom: 5%;
    
}


/* .item-timeline{
    background-color: rgb(15, 14, 14);
    margin-right: 44%;
    padding: 3%;
}

@media screen and (max-width: 1025px) {
    .item-timeline{
       
        margin-right: 0%;
        
    }
}

.roadmap-numbers{
    color: #c16777;
} */

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.layout_wrapper {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 240px);
    gap: 8px;
    min-width: 100%;
    max-width: 100%;
}

.box_7 {
    grid-column: 1/3;
}


.animated_box_wrapper {
    width: 100%;
    height: 100%;
    background-color: #d6d6d6;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    padding: 20px;
    font-weight: bold;
    font-size: 16px;
    font-family: 'SpaceGrotesk';
    color: rgba(0, 0, 0, 0.2);
}
.animated_box_wrapper:hover {
    background-color: #e2dfd9;
}

.animated_large_box_wrapper {
    position: absolute;
    background-color: #e2dfd9;
    pointer-events: none;
    opacity: 0;
    overflow: hidden;
    min-width: 100%;
    max-width: 100%;
}

.close_icon {
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
    color: #555;
    z-index: 5;
    opacity: 0;
}

.content_wrapper {
    display: grid;
    grid-template-columns: auto;
    place-content: flex-start;
    gap: 30px;
    height: 100%;
    padding: 20px;
    /* opacity: 0; */
    
}
.image_div {
    width: 100%;
    height: 380px;
    /* background-color: #faf6f0; */
}


.description-roadmap{
    margin-top: 7%;
}

/* .box_6{
    pointer-events: none;

} */




@media only screen and (min-width: 768px) {
    .layout_wrapper {
        grid-template-columns: repeat(15, 1fr);
        grid-template-rows: repeat(11, 40px);
        max-width: 1000px;
        margin: 30px auto;
        gap: 5px;
        padding: 0;
        
    }

    .content_wrapper {
        grid-template-columns: auto 300px;
        padding: 45px;
    }

    .box_1 {
        grid-column: 1/5;
        grid-row: 1/7;
    }

    .box_2 {
        grid-column: 1/5;
        grid-row: 7/33;
    }

    .box_3 {
        grid-column: 5/11;
        grid-row: 1/8;
    }

    .box_4 {
        grid-column: 5/8;
        grid-row: 8/33;
    }
    .box_5 {
        grid-column: 8/11;
        grid-row: 8/33;
    }

    .box_6 {
        grid-column: 11/16;
        grid-row: 1/7;
        /* pointer-events: none; */
        
    }

    .box_7 {
        grid-column: 11/16;
        grid-row: 7/33;
        
    }

    .largebox-demo{
        width: 85%;
        margin: auto;
       
    }

    
}


/* @media only screen and (max-width: 768px) {
    .layout_wrapper {
        grid-template-columns: repeat(15, 1fr);
        grid-template-rows: repeat(11, 40px);
        max-width: 1000px;
        margin: 30px auto;
        gap: 5px;
        padding: 0;
    }

    .content_wrapper {
        grid-template-columns: auto 300px;
        padding: 45px;
    }

    .box_1 {
        grid-column: 1/5;
        grid-row: 1/8;
    }

    .box_2 {
        grid-column: 1/5;
        grid-row: 8/12;
    }

    .box_3 {
        grid-column: 5/11;
        grid-row: 1/6;
    }

    .box_4 {
        grid-column: 5/8;
        grid-row: 6/12;
    }
    .box_5 {
        grid-column: 8/11;
        grid-row: 6/12;
    }

    .box_6 {
        grid-column: 11/16;
        grid-row: 1/7;
    }

    .box_7 {
        grid-column: 11/16;
        grid-row: 7/12;
        
    }
} */



@media only screen and (max-width: 800px) {
   

    .children-titre1{
        font-size: 10px;
        margin-top: 22vh;
    }
    .children-titre2{
        margin-top: 22vh;
        font-size: 10px;
    }

    .children-titre3{
        margin-top: 22vh;
        font-size: 10px;
    }
    
    .children-titre4{
        font-size: 10px;
        margin-top: 22vh;
    }

    .children-titre5{
        font-size: 10px;
        margin-top: 22vh;
    }
    .children-titre6{
        margin-top: 21vh;
        font-size: 10px;
    }
    .children-titre7{
        margin-top: 22vh;
        font-size: 10px;
    }

    /* .box_6{
        pointer-events: none;
    } */


    .animated_box_wrapper{
        
    }
}

@media only screen and (min-width: 1500px) {
    .animated_box_wrapper {
        height: 100%;
    }

    .layout_wrapper{
        gap: 10px;
    }

    .box_1 {
        grid-column: 1/5;
        grid-row: 1/7;
    }

    .box_2 {
        grid-column: 1/5;
        grid-row: 7/24;
    }

    .box_3 {
        grid-column: 5/11;
        grid-row: 1/8;
    }

    .box_4 {
        grid-column: 5/8;
        grid-row: 8/24;
    }
    .box_5 {
        grid-column: 8/11;
        grid-row: 8/24;
    }

    .box_6 {
        grid-column: 11/16;
        grid-row: 1/8;
        /* pointer-events: none; */
    }

    .box_7 {
        grid-column: 11/16;
        grid-row: 8/24;
        
    }

    
}


@media screen and (min-width: 200px) and (max-width: 380px) {

    .children-titre1{
        font-size: 12px;
        margin-top: 21vh;
        margin-left: 1vh;
    }
    .children-titre2{
        margin-top: 21vh;
        font-size: 12px;
    }

    .children-titre3{
        margin-top: 21vh;
        font-size: 12px;
    }
    
    .children-titre4{
        font-size: 12px;
        margin-top: 24vh;
    }

    .children-titre5{
        font-size: 12px;
        margin-top: 21vh;
    }
    .children-titre6{
        margin-top: 21vh;
        font-size: 12px;
    }
    .children-titre7{
        margin-top: 23vh;
        font-size: 12px;
    }

    /* .box_6{
        pointer-events: none;
    } */

}