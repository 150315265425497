


.header-team-container{
    text-align: center;
    margin-bottom: 5%;
    margin-top: 10%;
}

.about-us-container{
    text-align: center;
    color: white;
    position:relative;
    font-family: 'SpaceGrotesk';
    font-size: 1rem;
    width: 80%;
    margin: auto;
    margin-bottom: 5%;
}

.team-row1-container{
    z-index: 2;
    position: relative;
    display: flex;
    max-width: 65%;
    justify-content: space-evenly;
    margin: auto;

}

.team-row2-container{
    display: flex;
    max-width: 70%;
    justify-content: space-evenly;
    margin: auto;
    margin-top: 1%;

}


.team-row3-container{
    display: flex;
    max-width: 70%;
    justify-content: space-evenly;
    margin: auto;
    margin-top: 1%;

}


.video-team-wrapper{
    width: fit-content;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%);
  }


  @media screen and (max-width: 1500px) {
      .about-us-container{
          font-size: 1rem;
         
      }
  }

  


