
.item-container{
    /* width: 20%;   */
    margin-bottom: 5%;
    
}


#team-image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border-radius: 50%;
    box-shadow: 10px 10px 50px 30px rgba(82, 81, 81, 0.431);
    border: 1px solid #fff;
}

.team-role {
    
}

.team-link {
    text-align: center;
}

p.team-name-text{
    text-align: center;
    color: white;
    font-size: 1.4em;
    font-family: 'SpaceGrotesk';
    margin-top: 5%;

}

p.team-role-text{
    color: white;
    font-size: 1.1em;
    text-align: center;
    font-family: 'SpaceGrotesk';
    margin-top: 1%;
}

@media screen and (max-width: 1500px) {

    .item-container{
        max-width: 65%;  
        
    }

    p.team-role-text{
        font-size: 1rem;
        
    
    }

    p.team-name-text{
        font-size: 1.3em;
    
    }

}

@media screen and (min-width: 1500px) {
    .item-container{
        max-width: 30vh;  
        min-width: 20vh;  
    }

    p.team-role-text{
        font-size: 1rem;
        
    
    }

    p.team-name-text{
        font-size: 1.3em;
    
    }


}


