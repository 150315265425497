.appel-action-container{
    max-width: 100%;
    margin: auto;
    margin-bottom: 15%;
    position: relative;
    max-height: 100%;
    min-height: 60vh;
   /* border-radius: 26%; */
    border-width: 3px;
    border-color: rgba(126, 126, 126, 0.431) ;
    text-align: center;
    overflow: hidden;
}



.text-appel-action-container{
    position: absolute;
    width: 100%;
    font-size: 2rem;
    top: 50%;
    transform: translateY(-25%);
    /* margin-top: 18%;
    margin-left: 24%; */
    display: block;

}


p.appel-action-text{
   font-family: 'SpaceGrotesk-Bold';
   color: white;

}

.discord-appel{
    font-size: 4rem;
    margin: auto;
    
}

#dis-appel-but{
    font-size: 4rem;
}

.action{
    background-color: aliceblue;
}

.appel-action-container video{
    width: 100%;
    height: 100%;
}

.video-appele-action{
    overflow: hidden;
}

.discord-icone-appel{
    cursor: pointer;
}

@media screen and (max-width: 1025px) {
    .appel-action-container{
        min-height: 24vh;

    }

    .text-appel-action-container{
        font-size: 0.8rem;
    }

    

   
    #dis-appel-but{
        font-size: 1.6rem;
    }
}


