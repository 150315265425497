@font-face {
  font-family: "Hakubo";
  src: local("Hakubo"),
   url("./assets/fonts/Hakubo.ttf") format("truetype");
}

@font-face {
  font-family: "Osake";
  src: local("Osake"),
   url("./assets/fonts/Osake.ttf") format("truetype");
}

@font-face {
  font-family: "KGBlankSpaceSolid";
  src: local("KGBlankSpaceSolid"),
   url("./assets/fonts/KGBlankSpaceSolid.ttf") format("truetype");
}

@font-face {
  font-family: "Korean_Calligraphy";
  src: local("Korean_Calligraphy"),
   url("./assets/fonts/Korean_Calligraphy.ttf") format("truetype");
}


@font-face {
  font-family: "Dekiru";
  src: local("Dekiru"),
   url("./assets/fonts/Dekiru.ttf") format("truetype");
}

@import url('https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,100;0,400;1,300&family=Montserrat:wght@200;300&display=swap');

@font-face {
  font-family: "SpaceGrotesk";
  src: local("SpaceGrotesk"),
   url("./assets/fonts/SpaceGrotesk.ttf") format("truetype");
}


@font-face {
  font-family: "SpaceGrotesk-Bold";
  src: local("SpaceGrotesk"),
   url("./assets/fonts/SpaceGrotesk-Bold.otf") format("truetype");
}

@font-face {
  font-family: "Karasha";
  src: local("Karasha"),
   url("./assets/fonts/Karasha.ttf") format("truetype");
}

.App{
  background-color: black;
  letter-spacing: 1.8px;
  overflow: hidden;
}





