

.homepage-container{

    position: relative;
    min-height:900px;
}

strong{
    color:#fff;
}

.navbar{
    position: absolute;
width: 100%;
}
.navbar, .navbar-menu{
    background-color:transparent;
}

.navbar-burger{
    width: 6rem;
}
.navbar-burger img{
    width: 3rem;
}
.navbar-item img{
    max-height: inherit;
}

.navbar-burger span{
    background-color: #FFFFFF;
}

.buttons-mangaverse{
    padding: 1.2%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    top: 55vh;
    bottom: 0;
    right: 0;
    width: fit-content;
    height: fit-content;
    border:0.1em solid #FFFFFF;
    border-radius:1em;
    box-sizing: border-box;
    font-family:'SpaceGrotesk';
    font-weight:300;
    color:#FFFFFF;
    text-align:center;
    transition: all 0.2s;

}

.buttons-mangaverse:hover{
    border:0.1em solid #c16777;

}

#but-mangaverse{
    text-decoration: none;
    color: white;
}

#but-mangaverse:hover{
    color:#c16777;
}



.header-container{
    width: 100%;
    min-height: 20vh;

    position: relative;
}


.logo-container{
    position: fixed;
    left:5%;
    top: 3.6%;
}


#l-img{
    min-width: 70px;
    max-width: 70px;
    margin-left: 5%;
}


.button-container{
    height: fit-content;
    display: flex;
    position: absolute;
    left: 70%;
    top: 4%;
    max-width: 50vw;  
    
}


.link-twitter-container, .link-opensea-container{
    display: flex;
    margin-right: 1.2vw;
}

.link-twitter-container, .link-opensea-container{
}


#force-image ,#eternite-image {
    max-width: 50px;
    max-height: 40px;
    margin: auto;
}

 #twitter,#opensea,#discord {
    margin: auto;
    font-size: 1.3em;
    text-decoration: none;
    font-family: 'SpaceGrotesk-Bold';
    color: white;
    cursor: pointer;
    
}

#opensea {
    min-width: 200px;
    max-width: 200px;
}

#global_canvas{
    left: 50vw;
    top:-12vh;
    position: absolute;
    transform: translateX(-50%);
}

#twitter:hover{
    color: tomato;

}

#opensea:hover{
    color: #ff6347;

}

.link-discord-container{
    background-color: #ff6347;
    
    
}

#discord:hover{
    color: #ff6347;

}

#help{
    background-color: #ff6347;
    
}



.boutton-action-header{
    background-color: aquamarine;
    position: absolute;
}


.text-logo-new{
    font-family: "Karasha";
    color: white;
    font-size: 50px;
    margin-left: 5%;
}

.title-mobile-container{
   color: black;
}

#discord-mobile{
    display: none;
}

#twitter-mobile{
    display: none;
}


@media screen and (max-width: 1600px) {

}
@media screen and (max-width: 1400px) {

}
@media screen and (max-width: 1200px) {

}
@media screen and (max-width: 1000px) {

}

@media screen and (max-width: 851px) {
    .navbar-burger{
        transform: scale(1.8);
        margin-top: 4%;

    }

    .text-logo-new{
        display: none;
    }

    #l-img{
       width: 3%;    
    }
    

    .homepage-container{
        clip-path: ellipse(1000px 1000px at 50% 24%);
        position: relative;
        min-height: 900px;
    }

    .buttons-mangaverse{
        padding: 1.2%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        top: 45vh;
        bottom: 0;
        right: 0;
        width: fit-content;
        height: fit-content;
        border:0.1em solid #FFFFFF;
        border-radius:1em;
        box-sizing: border-box;
        font-family:'SpaceGrotesk';
        font-weight:300;
        color:#FFFFFF;
        text-align:center;
        transition: all 0.2s;
        
    }

    .title-mobile-container{
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        top: 45vh;
        bottom: 0;
        right: 0;
        width: fit-content;
        height: fit-content;
        font-family:'Karasha';
        color:#FFFFFF;
        text-align:center;
        transition: all 0.2s;
        font-size: 50px;

    }

    .header-container{
        width: 100%;
        min-height: 20vh;
    
        position: relative;
    }

    .header-container{
        width: 100%;
        min-height: 20vh;
    
        position: relative;
    }
    
    
    .logo-container{
        position: fixed;
        left:3%;
        top: 3.6%;
    }
    
    
    #l-img{
        max-width: 50px;
        min-width: 50px;
    }
    .button-container{
        height: fit-content;
        display: flex;
        position: absolute;
        left: 70%;
        top: 4%;
        max-width: 50vw;  
        
    }
    
    
    .link-twitter-container, .link-opensea-container{
        display: flex;
        margin-right: 1.2vw;
    }


    .navbar-menu{
        /* border: solid #c16777; */

        background: rgba(0,0, 0, 0.5);
        
        
        
        
    }

    #discord-mobile{
        display: block;
    }
    
    #twitter-mobile{
        display: block;
    }



}


@media screen and (min-width: 1500px) {
    .buttons-mangaverse{
        top: 63vh;
    }

    .video-container{
        min-width: 2000px;
        max-width: 2000px;
    }

    #global_canvas{
        min-width: 2000px;
        max-width: 2000px;

    }

}


@media screen and (min-width: 2000px) {
    .buttons-mangaverse{
        top: 90vh;
    }

    .video-container{
        min-width: 2000px;
        max-width: 2000px;
    }

    #global_canvas{
        min-width: 2500px;
        max-width: 2500px;

    }

}


@media screen and (min-width: 900px) and (max-width: 980px) { 

    .buttons-mangaverse{
        top: 33vw;
        left: 50%;
    }

    .navbar-burger{
        transform: scale(1.8);
        margin-top: 2%;
    }



}


@media screen and (min-width: 300px) and (max-width: 350px)  { 
    

    .buttons-mangaverse{
        top: 80vw;
        left: 50%;
    }

    .title-mobile-container{
        padding-top: 40%;
    }
}