/* .universe-container{

}

.gif-container{
    margin: auto;
    width: fit-content;

} */


*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.header1{
    /* margin-bottom: 20%; */
}

.gap_section-up {
    width: 100%;
    height: 1vh;
    background: black;
}
.gap_section-bottom{
    width: 100%;
    height: 20vh;
    background: black;
}

.container_wrapper {
    max-width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 25px;
}

.circles_wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* gap: 10px; */
    flex-wrap: wrap;
    width: 85%;
    place-items: center;
    margin-top: 30%;
}

.circle_wrapper {
    position: relative;
}

.square_box_wrapper {
    position: absolute;
    overflow: hidden;
    overflow-x: auto;
    bottom: 100%;
    background-color: black;
    display: flex;
    flex-direction: column;
    opacity: 0;
    pointer-events: none;
    transition: 0.3s ease-in-out;
    padding: 10px;
    z-index: 10;
}
.square_box_wrapper._show_square {
    bottom: calc(100% + 25px);
    opacity: 1;
    pointer-events: all;
    background-color: black;
}
.square {
    min-width: 200px;
    max-width: 200px;
    /* aspect-ratio: 16/9; */
    overflow: hidden;
    margin-bottom: 15px;
}
.circle {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    background-color: black;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    user-select: none;
}
.close_overly {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
}

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.description_box {
    font-size: 1rem;
    font-weight: 500;
    color: white;
    font-family: 'SpaceGrotesk';
}

#uni-text{
    font-size: 1.4rem;
}

@media only screen and (min-width: 851px) {

    .header1{
        font-size: 1.2rem;
        
    }
    .circles_wrapper {
        grid-template-columns: repeat(7, 1fr);
        position: relative;
    }

    .circle_wrapper {
        position: static;

    }

    .square_box_wrapper {
        width: 100%;
        flex-direction: row;
        left: 0 !important;
        transform: translateX(0) !important;
    }
    .description_box {
        padding-left: 20px;
        width: 80%;
        font-family: 'SpaceGrotesk';
        margin-top: 10vh;
        margin-left: 5vw;
    }
    .square {
        min-width: 350px;
        max-width: 350px;
        margin-bottom: 0;
    }

    
}

@media only screen and (max-width: 851px) {
    .gap_section-up{
        height: 18vh;
    }

    .header1{
        margin-bottom: 40%;
    }
    .circle_wrapper:nth-child(1) .square_box_wrapper,
    .circle_wrapper:nth-child(2) .square_box_wrapper,
    .circle_wrapper:nth-child(3) .square_box_wrapper,
    .circle_wrapper:nth-child(4) .square_box_wrapper,
    .circle_wrapper:nth-child(5) .square_box_wrapper,
    .circle_wrapper:nth-child(6) .square_box_wrapper,
    .circle_wrapper:nth-child(7) .square_box_wrapper {
       background-color: rgb(48, 46, 46);
       width: 320px;
       
    }

    .circle_wrapper:nth-child(1) .square_box_wrapper._show_square,
    .circle_wrapper:nth-child(2) .square_box_wrapper._show_square,
    .circle_wrapper:nth-child(3) .square_box_wrapper._show_square,
    .circle_wrapper:nth-child(4) .square_box_wrapper._show_square,
    .circle_wrapper:nth-child(5) .square_box_wrapper._show_square,
    .circle_wrapper:nth-child(6) .square_box_wrapper._show_square,
    .circle_wrapper:nth-child(7) .square_box_wrapper._show_square {
        background-color: rgb(48, 46, 46);
        
        
    }

    .circle_wrapper:nth-child(1) .square_box_wrapper._show_square{
        margin-bottom: 45%;
        /* margin-left: 15%; */
       
    }
    .circle_wrapper:nth-child(2) .square_box_wrapper._show_square{
        margin-left: -100%;
        margin-bottom: 40%;
       
    }

    .circle_wrapper:nth-child(3) .square_box_wrapper._show_square{
        margin-bottom: 120%;
        /* margin-left: 15%; */
    }
    .circle_wrapper:nth-child(4) .square_box_wrapper._show_square{
        margin-bottom: 130%;
        margin-left: -100%;
    }
    .circle_wrapper:nth-child(5) .square_box_wrapper._show_square{
        margin-bottom: 180%;
        /* margin-left: 15%; */
    }
    .circle_wrapper:nth-child(6) .square_box_wrapper._show_square{
        margin-bottom: 180%;
        margin-left: -100%;
    }
    .circle_wrapper:nth-child(7) .square_box_wrapper._show_square{
        margin-bottom: 300%;
        /* margin-left: 15%; */
    }

    .square{
        margin: auto;

    }
    .description_box {
        font-size: 0.8rem;
    }

    #uni-text{
        font-size: 1.1rem;
    }

    .circles_wrapper {
        margin-top: 90%;
    }


    .circle {
        width: 70%;
        height: 70%;
        margin: auto;
    }

   

}


@media only screen and (max-width: 391px) {

    .header1{
        margin-bottom: 40%;
    }
    .circle_wrapper:nth-child(1) .square_box_wrapper,
    .circle_wrapper:nth-child(2) .square_box_wrapper,
    .circle_wrapper:nth-child(3) .square_box_wrapper,
    .circle_wrapper:nth-child(4) .square_box_wrapper,
    .circle_wrapper:nth-child(5) .square_box_wrapper,
    .circle_wrapper:nth-child(6) .square_box_wrapper,
    .circle_wrapper:nth-child(7) .square_box_wrapper {
       background-color: rgb(48, 46, 46);
       width: 320px;
       
    }

    .circle_wrapper:nth-child(1) .square_box_wrapper._show_square,
    .circle_wrapper:nth-child(2) .square_box_wrapper._show_square,
    .circle_wrapper:nth-child(3) .square_box_wrapper._show_square,
    .circle_wrapper:nth-child(4) .square_box_wrapper._show_square,
    .circle_wrapper:nth-child(5) .square_box_wrapper._show_square,
    .circle_wrapper:nth-child(6) .square_box_wrapper._show_square,
    .circle_wrapper:nth-child(7) .square_box_wrapper._show_square {
        background-color: rgb(48, 46, 46);
        
        
    }

    .circle_wrapper:nth-child(1) .square_box_wrapper._show_square{
        margin-bottom: 45%;
        margin-left: -15%;
       
    }
    .circle_wrapper:nth-child(2) .square_box_wrapper._show_square{
        margin-left: -115%;
       
    }

    .circle_wrapper:nth-child(3) .square_box_wrapper._show_square{
        margin-bottom: 100%;
        margin-left: -15%;
    }
    .circle_wrapper:nth-child(4) .square_box_wrapper._show_square{
        margin-bottom: 95%;
        margin-left: -115%;
    }
    .circle_wrapper:nth-child(5) .square_box_wrapper._show_square{
        margin-bottom: 155%;
        margin-left: -15%;
    }
    .circle_wrapper:nth-child(6) .square_box_wrapper._show_square{
        margin-bottom: 160%;
        margin-left: -115%;
    }
    .circle_wrapper:nth-child(7) .square_box_wrapper._show_square{
        margin-bottom: 300%;
        margin-left: -15%;
    }

    .square{
        margin: auto;

    }
    .description_box {
        font-size: 0.8rem;
    }

    #uni-text{
        font-size: 1.1rem;
    }

    .circles_wrapper {
        margin-top: 90%;
    }


    .circle {
        width: 70%;
        height: 70%;
        margin: auto;
    }

   
    @media only screen and (min-width: 1500px) {
        .header1{
            margin-top: 20%;
        }

    }
}




@media screen and (min-width: 900px) and (max-width: 1100px) and (min-height: 1300px)  { 
    .square{
        
        
    }


    .circles_wrapper{
        margin-top: 50%;
        
    }


    

}

