

.how7-container{
    width: 100%;
    margin: auto;
    
    
}

h2{
    font-family: "SpaceGrotesk-Bold";
    color: #c16777;
    font-size: 1.4rem;
    margin-bottom: 4%;
    

}




.all-page-container{
    margin-bottom: 5%;
    
}

.caroussel-container{
    /* position: absolute;
    left: 55%; */
    /*margin-top: 10%;*/
    width: 80%;
    margin: auto;
    /* margin-top: 5vh; */
    margin-right: 15vw;
    
}

.all-text-container{
    position: absolute;
    left: 5%;
    margin-top: 5%;
    margin-bottom: 10%;
}




.button-mangaverse{
    padding:0.35em 1.2em;
    /* max-width: 25%; */
    border:0.1em solid #FFFFFF;
    border-radius:1em;
    box-sizing: border-box;
    font-family:'SpaceGrotesk';
    font-weight:300;
    color:#FFFFFF;
    text-align:center;
    transition: all 0.2s;
    margin: auto;
    margin-top: 5%;

}
.button-mangaverse:hover{
    border:0.1em solid #c16777;

}

#but-mangaverse{
    text-decoration: none;
    color: white;
}

#but-mangaverse:hover{
    color:#c16777;
}




.text1-container{
    color: white;
    font-family: 'SpaceGrotesk';
    font-size: 1rem;
    left: 10%;
    /* text-align: center; */
    margin-top: 8%;
    margin-left: 2%;

}

.text2-container{
    color: white;
    font-family: 'SpaceGrotesk';
    font-size: 1rem;
    /* text-align: center; */
    margin: auto;
    margin-right: 2%;

}
.text3-container{
    color: white;
    font-family: 'SpaceGrotesk';
    font-size: 1rem;
    /* text-align: center; */
    margin: auto;
    margin-right: 2%;

}


/* p.titles{
    color: #c16777;
    text-align: center;
    font-size: 1.8rem;
    /* margin-top: 7vh; 
    }*/



.item-caroussel-class{
    max-width: 100%;
}


.caroussel2-container{
    width: 85%;
    margin: auto;
    
}



.video-wrapper{
  width: fit-content;
  margin-left: 10%;
  position: relative;
  bottom: 20%;
  right: 30%;


}


.screenseven{
    margin-left: 7vw;
}

.textsecond{
    margin-top: 5vh;
    margin-right:7vw ;
}



@media screen and (max-width: 851px) {

    .button-mangaverse{
        max-width: 42vw;
        min-width: 42vw;
        
        font-size: 1rem;

    }
    

    #but-mangaverse{
        font-size: 0.8rem;
    }

    h2{
        font-size: 1rem;
        /* margin-bottom: 15%; */
    }

    p.titles{
        /* margin-bottom: 10%; */
        font-size: 1rem;
    }


    .text1-container{
        
        font-size: 1rem;
        margin: auto;
        text-align: center;
    }

    .text2-container{
        text-align: center;
    }

    .column{
        font-size: 1rem;
    }


    .text3-container{
        
        font-size: 1rem;
        text-align: center;
        
    
    }

    .screenseven{
        margin-top: 1%;
        margin: auto;
    }

    .textsecond{
        margin: auto;
        margin-top: 5vh;
        
    }
        
    .caroussel-container{
        margin: auto;
    }

    .header1-container h2{
        font-size: 1.2rem;
    }

    .caroussel-container{
        margin-bottom: -10vh;
    }

}

/* @media screen and (min-width: 851px) {
    .button-mangaverse{
        max-width: 20vw;
    }


} */


@media screen and (min-width: 1500px) {

   

    .text1-container{
        margin-top: 4%;
    }

    .button-mangaverse{
        max-width: 20vw;
        min-width: 20vw;
    }


    .textsecond{
        margin-right: 9vw;
    }

    .caroussel2-container{
        margin-left: 10vw;
    }

    p.titles strong {
        color: #c16777;
        font-size: 1.5rem;
    }

    .caroussel-container{
        margin-right: 15vw;
    }

    .screenseven{
        margin-top: 0vh;
    }


}

@media screen and (max-width: 1500px) {
    .text1-container{
        margin-top: 5%;
    }

    .titles-c{
        margin-top: 5%;
    }

    h2{
        font-size: 1.4rem;
    }

    .button-mangaverse{
        max-width: 20vw;
    }

    p.titles strong{
        color: #c16777;
        font-size: 1.4rem;
    }


    .screenseven{
        margin-top: 0vh;
    }

    .textsecond{
        margin-top: 5vh;
    }

}  

    @media screen and (min-width: 1100px) and (max-width: 1260px) { 

        .screenseven{
            margin-top: 0vh;
            font-size: 0.9rem;
        }

        .textsecond{
            margin-top: -2vh;
            font-size: 0.9rem;
            
        }
        .text3-container{
            font-size: 0.9rem;
        }

        .caroussel-container{
            margin-right:2vw;
        }
    }


    @media screen and (min-width: 900px) and (max-width: 1100px) { 
        .screenseven{
            margin-top: 0vh;
            font-size: 0.9rem;
        }

        .textsecond{
            margin-top: -5vh;
            font-size: 0.9rem;
            
        }
        .text3-container{
            font-size: 0.9rem;
        }
        

    
    }


    @media screen and (min-width: 1527px) and (max-width: 1770px) { 

        .screenseven{
            margin-top: -5vh;
            font-size: 0.9rem;
        }
        .textsecond{
            margin-top: 0vh;
            font-size: 0.9rem;
        }

            
        
    }


    @media screen and (min-width: 1260px) and (max-width: 1360px) { 
        .textsecond{
            margin-top: 5vh;
            font-size: 0.9rem;
        }

        .text3-container{
            font-size: 0.9rem;
        }
    }